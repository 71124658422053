import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../core/util/util.service';
import { Router } from '@angular/router';
import { emailValidator } from '../../core/util/email-validator.directive';
import { AuthService } from '../auth.service';
import { ToastService } from '../../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_MODEL_DATA } from '../../core/const-data';
import { LoaderService } from '../../core/util/loader.service';

import { ModalService } from '../../core/util/modal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';

interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  ln: string;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  // TODO: This is  todo
  // ! This is important
  // * high light
  // ? Question 
  // normal comment

  loginForm!: FormGroup;
  user: IUser;
  expires = new Date();
  isIdTokenPresent = this.authService.getToken('idToken');
  refreshToken = this.authService.getToken('refreshToken');
  expireInterval = this.authService.getLocalStoarge('expireInterval');
  regularExpression = new RegExp(String.raw`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`])[a-zA-Z0-9!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`]{${environment.passwordMinLength},${environment.passwordMaxLength}}$`);

  constructor(private translateService: TranslateService, private authService: AuthService, private utilService: UtilService, public router: Router, private fb: FormBuilder, private toastService: ToastService,private loaderService: LoaderService,private modalService: ModalService) {
    this.user = {} as IUser;
    this.utilService.checkLanguageConstraintForSignIn();
  }
  ngOnInit(): void {
    this.loginFormInit();
    if(!this.isIdTokenPresent && this.refreshToken){
      let grantType = 'refresh_token';
      this.callCognitoToken(grantType,this.refreshToken, Number(this.expireInterval));
    }
    let validToken = this.authService.isAuthenticated();
    if(validToken){
      this.router.navigate([ 'owners-center']);
    }
  }

  loginFormInit(){
    // this.loginForm = new FormGroup({
    //   email: new FormControl(this.user.email, [
    //     Validators.required,
    //     Validators.maxLength(100),
    //     emailValidator(),
    //   ]),
    //   password: new FormControl(this.user.password, [
    //     Validators.required
    //   ]),
    // });
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, emailValidator(),Validators.maxLength(100)]],
      password: ['', [Validators.required]],
    });
    this.loginForm.controls["password"].setValidators([Validators.required,Validators.pattern(this.regularExpression)]);

  }

  public validate(): void {
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    }
   this.loginForm.controls['email'].value.trim();
    this.loginForm.controls['password'].value.trim();
    this.user = this.loginForm.value;
    this.onSubmit();
  }

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  callCognitoToken(grantType: string , token: string,expireInterval: number){
    this.authService.cognitoToken(grantType,token)?.subscribe((data:any)=>{
      let _now = new Date();
      _now.setTime(_now.getTime() + 1 * (expireInterval * 1000));
      let expires = this.authService.add_year(this.expires,APP_MODEL_DATA.getExpiryTime).toString();
      let id_token = `idToken=${data?.id_token};  expires=${_now.toUTCString()}; path=/;Priority=High;secure;`;
      document.cookie = id_token;
      document.cookie = `refreshToken=${token}; expires=${expires}; path=/;Priority=High;secure;`;
      this.checkSession();
    },
    (error) => {
      console.log(error.error);
    })
  }

  checkSession(){
    this.authService.userSession()?.subscribe((session:any)=>{
      this.authService.setLocalStorage('sessionId',session.session_user_id);
      this.userAccount(session?.session_user_id)
    },
    (error) => {
      console.log(error.error);
      this.loaderService.hide();
    })
  }

  userAccount(sessionId : string){
    this.authService.userAccount(sessionId)?.subscribe((userInfo:any)=>{
      this.authService.setLocalStorage('userInfo',JSON.stringify(userInfo.account));
      this.authService.setLocalStorage("rubyUserId", JSON.stringify(userInfo.account.userId));

      let userDetails = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
      if(this.authService.checkUserRole(userDetails)){
        this.router.navigate([ 'owners-center']);
      // } else if (userDetails?.userLevel == 'dev'){
      //   this.router.navigate([ 'engineering']);
      // 
        } else if( userDetails?.userLevel == 'support' || userDetails?.userLevel == 'support_user' || userDetails?.userLevel == 'dev' || userDetails?.userLevel == 'prog'){ // engineering
          this.openModal();
        }
      this.loaderService.hide();
      if(this.authService.checkUserRole(userDetails)){
        this.toastService.showSuccess(userInfo?.account?.firstName +' '+ userInfo?.account?.lastName, this.translateService.instant('lang.heading_welcome'));
      }
    },
    (error) => {
      console.log(error.error);
      this.loaderService.hide();
    })
  }


  onSubmit(): void {
    if (this.loginForm.valid) {
      let credentials = this.loginForm.value;
      credentials.language = this.authService.getLocalStoarge('language')
      delete credentials.showpassword;
      this.authService.signIn(credentials)?.subscribe(
        (response: any) => {
          let refreshToken = response?.userPoolOAuth?.RefreshToken;
          let expireInterval = response?.userPoolOAuth?.ExpiresIn;
          this.authService.setLocalStorage('callToken',refreshToken);
          this.authService.setLocalStorage('expireInterval',expireInterval);
          let grantType = 'refresh_token';
          this.callCognitoToken(grantType,refreshToken,expireInterval);
        },
        (error) => {
          console.log(error.error);
        }
      );
    }
  }
 // * Open model pop up
 openModal(): void { 
  const title = 'Warning';
  const data =`  <p><span>Invalid credentials. If you are a support user, please visit </span>
  <span><a>Support Portal</a></span>
  <span> to access the iAqualink Support Portal.</span></p>`
  const content = data;
  const isHTML = true;
  const buttonsConfig = [
    {  class:"btn btn-prime",label: 'Support Portal', action: () => this.navigateSupportPortal() }
  ];
  const onClose = () => {};
  const onShow = () => {};
  const modalConfig: ModalOptions = {
    class: 'modal-dialog-centered', // ? Center modal vertically
    backdrop: 'static', // ? Prevent modal from closing on backdrop click
    keyboard: false, // ? Prevent modal from closing on escape key press
  };
  this.loaderService.hide();
  this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
}

navigateSupportPortal():void{
  window.location.href = environment.supportUrl;
  // this.modalService.closeModal();
  // this.router.navigate(['/signin'])
}

}
